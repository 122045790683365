import {
  Breadcrumbs,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Col, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import HomeIcon from "@material-ui/icons/Home";
import { Button } from "@mui/material";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import axios from "axios";
import config from "../../config";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #f2f2f2",
  },
}));
function Shopify() {
  const classes = useStyles();
  const theme = useTheme();
  const [shopifyForm, setShopifyForm] = React.useState({
    shopifyApiKey: "",
    shopifyApiSecretKey: "",
    shopifyHostName: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [isValidLoading, setIsValidLoading] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [isKeyValid, setIsKeyValid] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [shopifyKeys, setShopifyKeys] = React.useState({
    shopifyApiKey: "",
    shopifyApiSecretKey: "",
    shopifyHostName: "",
  });

  const handleFieldChange = (e) => {
    const { name, value, required } = e.target;

    // Check if the input is only white spaces
    const storedValue = value.trim() === "" ? "" : value;

    if (required && storedValue === "") {
      e.target.setCustomValidity(
        "This field cannot be blank or contain only spaces."
      );
    } else {
      e.target.setCustomValidity("");
    }

    // Set the value to the original or an empty string if it's just white space
    setShopifyForm((prev) => ({
      ...prev,
      [name]: storedValue,
    }));
    setIsKeyValid(false);
    setIsValid(false);
  };

  const getShopifyKeys = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("client"));
      const payload = {
        userId: user?._id,
      };
      await axios
        .get(`${config.apiUrl}//shopify/get-key`, { params: payload })
        .then((res) => {
          if (res?.data?.success) {
            setShopifyForm({
              shopifyApiKey: res?.data?.response?.shopifyApiKey || "",
              shopifyApiSecretKey: res?.data?.response?.shopifyApiSecretKey || "",
              shopifyHostName: res?.data?.response?.shopifyHostName || "",
            });
            setShopifyKeys({
              shopifyApiKey: res?.data?.response?.shopifyApiKey || "",
              shopifyApiSecretKey: res?.data?.response?.shopifyApiSecretKey || "",
              shopifyHostName: res?.data?.response?.shopifyHostName || "",
            });
            setIsKeyValid(false);
          }
        })
        .catch((err) => {
          console.error("add card error: ", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log("add card error: ", error);
      setLoading(false);
    }
  };

  const validateShopifyKeys = async () => {
    try {
      setIsValidLoading(true);
      const payload = {
        shopifyApiKey: shopifyForm?.shopifyApiKey,
        shopifyApiSecretKey: shopifyForm?.shopifyApiSecretKey,
        shopifyHostName:
          shopifyForm?.shopifyHostName?.replace(".myshopify.com", "") +
          ".myshopify.com",
      };

      await axios
        .post(`${config.apiUrl}/shopify/check-key`, payload)
        .then((res) => {
          if (res?.data?.success) {
            setIsKeyValid(true);
            setIsValid(true);
            toast.success(res?.data?.message);
          } else {
            toast.error("Key, Secret Key or Host Name is not valid.");
            setIsKeyValid(false);
          }
        })
        .catch((err) => {
          console.error("check-key error: ", err);
          toast.error(" Key or Secret Key or Host Name is not valid.");
          setIsKeyValid(false);
        })
        .finally(() => {
          setIsValidLoading(false);
        });
    } catch (error) {
      console.log("check-key error: ", error);
      setIsValidLoading(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      let user = JSON.parse(localStorage.getItem("client"));
      const payload = {
        userId: user?._id,
        shopifyApiKey: shopifyForm?.shopifyApiKey,
        shopifyApiSecretKey: shopifyForm?.shopifyApiSecretKey,
        shopifyHostName:
          shopifyForm?.shopifyHostName?.replace(".myshopify.com", "") +
          ".myshopify.com",
      };

      await axios
        .post(`${config.apiUrl}/shopify/add-key`, payload)
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            setIsKeyValid(false);
            setIsValid(false);
            getShopifyKeys();
          }
        })
        .catch((err) => {
          console.error("add card error: ", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log("add card error: ", error);
      setLoading(false);
    }
  };

  const handleRemoveKey = async () => {
    try {
      setLoading(true);

      let user = JSON.parse(localStorage.getItem("client"));
      const payload = {
        userId: user?._id,
      };

      await axios
        .post(`${config.apiUrl}/shopify/remove-key`, payload)
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            setIsKeyValid(false);
            setIsValid(false);
            getShopifyKeys();
          }
        })
        .catch((err) => {
          console.error("add card error: ", err);
        })
        .finally(() => {
          setLoading(false);
          setShowDelete(false);
        });
    } catch (error) {
      console.log("add card error: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      getShopifyKeys();
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Shopify</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col pl-md-0 pl-auto pr-md-0 pr-auto">
          <Card>
            <CardHeader
              title="Shopify Info"
              subheader="Manage your Shopify account"
              action={
                <>
                  {shopifyKeys?.shopifyApiKey && (
                    <IconButton
                      aria-label="delete campaign"
                      title="Delete Product"
                      onClick={() => setShowDelete(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </>
              }
            />
            <CardContent>
              <div>
                <Form validated={isValid} onSubmit={handleSave}>
                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <Form.Group>
                        <Form.Label>Shopify Api Key</Form.Label>
                        <Form.Control
                          style={{ height: "48px" }}
                          placeholder="Shopify Api Key"
                          type="text"
                          name="shopifyApiKey"
                          value={shopifyForm?.shopifyApiKey || ""}
                          onChange={handleFieldChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter the Shopify Api Key!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-lg-7 col-12">
                      <Form.Group>
                        <Form.Label>Shopify Api Secret Key</Form.Label>
                        <Form.Control
                          style={{ height: "48px" }}
                          placeholder="Shopify Api Secret Key"
                          type="text"
                          name="shopifyApiSecretKey"
                          value={shopifyForm?.shopifyApiSecretKey || ""}
                          onChange={handleFieldChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter the Shopify Api Secret Key!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-lg-7 col-12">
                      <Form.Group>
                        <Form.Label>Shopify Host Name</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            aria-describedby="basic-addon2"
                            style={{ height: "48px" }}
                            placeholder="Shopify Host Name"
                            type="text"
                            name="shopifyHostName"
                            value={
                              shopifyForm?.shopifyHostName?.replace(
                                ".myshopify.com",
                                ""
                              ) || ""
                            }
                            onChange={handleFieldChange}
                            required
                          />
                          <InputGroup.Text id="basic-addon2">
                            .myshopify.com
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          Please enter the Shopify Host Name!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-lg-7 col-12 justify-content-between d-flex ">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        type="button"
                        onClick={validateShopifyKeys}
                        disabled={(shopifyForm?.shopifyApiKey?.trim()==="" || shopifyForm?.shopifyApiSecretKey?.trim()==="" || shopifyForm?.shopifyHostName?.trim()==="")}
                      >
                        {isValidLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                          />
                        )}
                        Validate
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleIcon />}
                        type="submit"
                        disabled={!isKeyValid}
                      >
                        {loading && isKeyValid && !showDelete && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                          />
                        )}
                        Save
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </CardContent>
          </Card>
        </div>

        <Modal
          centered
          show={showDelete}
          onHide={() => setShowDelete(false)}
          size="md"
        >
          <Modal.Header closeButton variant="danger">
            <Modal.Title>DELETE PRODUCT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete{" "}
            <b>"your Shopify Keys and Host Name "</b>?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveKey()}
              className="mr-2"
            >
              {loading && showDelete && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              Yes
            </Button>
            <Button variant="contained" onClick={() => setShowDelete(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Shopify;
