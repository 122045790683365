import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentProduct } from "../../store/actions/index";
import config from "../../config";
import { axiosConfig } from "../../utils/utils";
import { debounce } from "lodash";
import ProductUsageViewsChart from "./ProductUsageViewChart";
import { ArrowOutward, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const columns = [{ id: "title", label: "Name", minWidth: 170 }];

const ShopifyProductTable = ({ headings, data, setPage, shopifyKeys }) => {
  const { category } = useSelector((state) => state.client);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState("ASC");
  const [searchQuery, setSearchQuery] = useState("");
  const [lastSearchQuery, setLastSearchQuery] = useState("");
  const [productsData, setProductsData] = useState([]);
  const [FilterData, setFilterData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [nextPageURL, setNextPageURL] = useState(""); // Replace with your API
  const [loading, setLoading] = useState(false);
  const limit = 10;
  const [chartProductId, setChartProductId] = useState(null);
  const [chartData, setChartData] = useState({
    coupon: 0, 
    usage:  0, 
    views:  0
  });
  const [chartLoading, setChartLoading] = useState(false);

  const dispatch = useDispatch();

  const getChartData = async (id) => {
    try {
      setChartLoading(true);
      const accessToken = localStorage.getItem("token");

      await axiosConfig(
        "get",
        `${config.apiUrl}/shopify/product/viewcount?productId=${id}`,
        null,
        accessToken
      ).then((res) => {
        // setPageCount(newPage);
        console.log("chart data", res?.data?.data);
        const data = {
          coupon: 0, 
          usage: res?.data?.data?.usageCount || 0, 
          views: res?.data?.data?.viewCount || 0
        }
        setChartData(data);
      });

      setChartLoading(false);
    } catch (error) {
      console.log("chat data error",error);
      
    }
  };

  const loaderRef = useRef(null);
  const handleSetChartProductId = (id) => {
    setChartData(null);
    if (id === chartProductId) {
      setChartProductId(null);
    } else {
      setChartProductId(id);
      getChartData(id);
    }
  };

  // const debouncedSearch = useCallback(
  //   debounce(async (query) => {
  //     const client = JSON.parse(localStorage.getItem("client"));
  //     const accessToken = localStorage.getItem("token");

  //     setPageCount(0);

  //     await axiosConfig(
  //       "POST",
  //       `${config.apiUrl}/shopify/fetch-products`,
  //       { userId: client._id, limit: limit, title: query },
  //       // "get",
  //       // `${config.apiUrl}/products?page=1&count=${rowsPerPage}&search=${query}&client_id=${client._id}`,
  //       // null,
  //       accessToken
  //     ).then((res) => {
  //       setLastSearchQuery(query);
  //       setProductsData(res?.data?.data?.products);
  //       setTotal(res?.data?.total);
  //     });
  //   }, 1000),
  //   [rowsPerPage]
  // );

  // const handleSearchInputChange = (e) => {
  //   const query = e.target.value;
  //   setSearchQuery(query);

  //   if (query !== lastSearchQuery) {
  //     debouncedSearch(query);
  //   }
  // };

  // const setCampaign = (campaign) => {
  //   localStorage.setItem("producrid", campaign._id);
  //   dispatch(setCurrentProduct(campaign));
  //   setPage("product-coupon-details");
  // };

  const handleChangePage = async (reset = false) => {
    if (!nextPageURL && !reset) return;
    try {
      setLoading(true);
      const client = JSON.parse(localStorage.getItem("client"));
      const accessToken = localStorage.getItem("token");

      await axiosConfig(
        "POST",
        `${config.apiUrl}/shopify/fetch-products`,
        { userId: client._id, limit: limit, nextPageURL },
        // "get",
        // `${config.apiUrl}/products?page=${newPage + 1}&count=10&category=${
        //   FilterData ? FilterData : ""
        // }&search=${searchQuery}&client_id=${client._id}`,
        // null,
        accessToken
      ).then((res) => {
        // setPageCount(newPage);
        setNextPageURL(res?.data?.data?.nextPageURL);
        setProductsData(
          reset
            ? res?.data?.data?.products
            : [...productsData, ...res?.data?.data?.products]
        );
        setTotal(res?.data?.total);
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPageCount(0);
  // };

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      data = [sorted];
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      data = [sorted];
      setOrder("ASC");
    }
  };

  // const SearchProduct = async () => {
  //   const client = JSON.parse(localStorage.getItem("client"));
  //   const accessToken = localStorage.getItem("token");

  //   await axiosConfig(
  //     "POST",
  //     `${config.apiUrl}/shopify/fetch-products`,
  //     { userId: client._id, limit: limit, title: searchQuery },
  //     // `${config.apiUrl}/products?page=${
  //     //   pageCount + 1
  //     // }&count=${rowsPerPage}&search=${searchQuery}&client_id=${client._id}`,
  //     // null,
  //     accessToken
  //   ).then((res) => {

  //     console.log("nextPageURL", res?.data?.data?.nextPageURL);
  //     setNextPageURL(res?.data?.data?.nextPageURL);
  //     setProductsData(res?.data?.data?.products);
  //     setTotal(res?.data?.total);
  //   });
  // };

  useEffect(() => {
    // SearchProduct();
    handleChangePage(true);
  }, []);

  // const FilteredProduct = async (e) => {
  //   setFilterData(e);
  //   const client = JSON.parse(localStorage.getItem("client"));
  //   const accessToken = localStorage.getItem("token");
  //   setPageCount(0);
  //   await axiosConfig(
  //     "POST",
  //     `${config.apiUrl}/shopify/fetch-products`,
  //     { userId: client._id, limit: 10, title: searchQuery },
  //     accessToken
  //   ).then((res) => {
  //     setProductsData(res?.data?.data?.products);
  //     setTotal(res?.data?.total);
  //   });
  // };

  // const ResetProducts = async () => {
  //   const accessToken = localStorage.getItem("token");
  //   const client = JSON.parse(localStorage.getItem("client"));
  //   await axiosConfig(
  //     "POST",
  //     `${config.apiUrl}/shopify/fetch-products`,
  //     { userId: client._id, limit: 10 },
  //     accessToken
  //   ).then((res) => {
  //     setProductsData(res?.data?.data?.products);
  //     setTotal(res?.data?.total);
  //   });
  // };

  const handleViewProduct = (product) => {
    const link = `https://${shopifyKeys?.shopifyHostName}/products/${product?.handle}`;
    window.open(link, "_blank");
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting && nextPageURL && !loading) {
          handleChangePage();
        }
      },
      { threshold: 1.0 }
    );

    if (loaderRef.current) observer.observe(loaderRef.current);

    return () => loaderRef.current && observer.unobserve(loaderRef.current);
  }, [nextPageURL, loading]);
  return (
    <>
      {/* <div className="search mb-2">
        <div className="row">
          <div className="col-lg-4 col-12 products_select mb-lg-0 mb-3">
            <select
              className="mr-lg-2 mr-0 form-control products_select_box"
              style={{ height: "38px" }}
              onChange={(e) => FilteredProduct(e.currentTarget.value)}
            >
              <option disabled>Select Category..</option>
              <option value="">All</option>
              {category?.length !== 0 &&
                category.map((cat, catIndex) => (
                  <option value={cat.category} key={catIndex}>
                    {cat.category}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-lg-5 col-12 pr-lg-0 pr-auto">
            <div className="searchFrom products_search_bar w-100">
              <input
                type="text"
                value={searchQuery}
                style={{ height: "38px" }}
                onChange={handleSearchInputChange}
                id="kt_filter_search"
                className="form-control"
                placeholder="Search.."
              />
              {searchQuery ? (
                <SearchOutlined onClick={(e) => SearchProduct()} />
              ) : (
                <SearchOutlined />
              )}
            </div>
          </div>
          <div
            className="col-lg-3 col-12 reset_button pl-0"
            style={{ textAlign: "end" }}
          >
            <Button
              style={{ height: "38px" }}
              variant="contained"
              color="primary"
              onClick={(e) => ResetProducts()}
            >
              Reset
            </Button>
          </div>
        </div>
      </div> */}
      {productsData?.length === 0 ? (
        <TableContainer>
          <Table style={{ minWidth: "750px" }}>
            <TableHead>
              <TableRow>
                {headings?.map((head, index) => (
                  <StyledTableCell key={index} align="left">
                    {head}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
          <div className="text-center"> {loading ? "Loading products..." : "No data found..."}</div>
         
        </TableContainer>
      ) : (
        <>
          <TableContainer>
            <Table style={{ minWidth: "750px" }}>
              <TableHead>
                <TableRow key={"header row"}>
                  {headings?.map((head, index) => (
                    <StyledTableCell
                      onClick={() => sorting("name")}
                      key={"header row call"+index}
                      align="left"
                    >
                      {head}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {productsData?.map((row, index) => {
                  return (
                    <>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        onClick={(e) =>{
                          e.stopPropagation(); 
                          handleSetChartProductId(row?.id);
                        }}
                      >
                        <TableCell key={index} align={"left"} className="position-relative px-4">
                       {chartProductId !== row?.id ? (<KeyboardArrowDown sx={{left:'-3px'}} className="position-absolute top-50" />) : (<KeyboardArrowUp sx={{left:'-3px'}} className="position-absolute top-50 start-0 " />) }
                          {index + 1}

                        </TableCell>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column?.format && typeof value === "number"
                                ? column.format(value)
                                : value ?? "N/A"}
                            </TableCell>
                          );
                        })}
                        <StyledTableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleViewProduct(row);
                            }}
                            // startIcon={<SearchIcon />}
                            endIcon={<ArrowOutward />}
                          >
                            View
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                      {chartProductId === row?.id ? (
                        <TableRow key={"chart " + index}>
                          <TableCell colSpan={3} key={"chart data"}>
                                  <ProductUsageViewsChart data={chartData} />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
                {/* <TableRow  ref={loaderRef}>
                  <TableCell colSpan={6} className="text-center">
                    {loading && <p>Loading more products...</p>}
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>

          <div ref={loaderRef} style={{ height: "50px", textAlign: "center" }}>
            {loading && <p>Loading more products...</p>}
          </div>
        </>
      )}
    </>
  );
};

export default ShopifyProductTable;
