import axios from "axios";
import React from "react";
import { Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import config from "../../config";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #f2f2f2",
  },
}));

const ShopifyModal = ({ showShopifyModal, setShowShopifyModal }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [shopifyForm, setShopifyForm] = React.useState({
    shopifyApiKey: "",
    shopifyApiSecretKey: "",
    shopifyHostName: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [isValidLoading, setIsValidLoading] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [isKeyValid, setIsKeyValid] = React.useState(false);
  const [shopifyKeys, setShopifyKeys] = React.useState({
    shopifyApiKey: "",
    shopifyApiSecretKey: "",
    shopifyHostName: "",
  });
  const [step, setStep] = React.useState(1);

  const handleFieldChange = (e) => {
    const { name, value, required } = e.target;

    // Check if the input is only white spaces
    const storedValue = value.trim() === "" ? "" : value;

    if (required && storedValue === "") {
      e.target.setCustomValidity(
        "This field cannot be blank or contain only spaces."
      );
    } else {
      e.target.setCustomValidity("");
    }

    // Set the value to the original or an empty string if it's just white space
    setShopifyForm((prev) => ({
      ...prev,
      [name]: storedValue,
    }));
    setIsKeyValid(false);
    setIsValid(false);
  };

  const getShopifyKeys = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("client"));
      const payload = {
        userId: user?._id,
      };
      await axios
        .get(`${config.apiUrl}/shopify/get-key`, { params: payload })
        .then((res) => {
          if (res?.data?.success) {
            setShopifyForm({
              shopifyApiKey: res?.data?.response?.shopifyApiKey||"",
              shopifyApiSecretKey: res?.data?.response?.shopifyApiSecretKey||"",
              shopifyHostName:  res?.data?.response?.shopifyHostName ? res?.data?.response?.shopifyHostName.replace(".myshopify.com", ""):"",
            });
            setShopifyKeys({
              shopifyApiKey: res?.data?.response?.shopifyApiKey||"",
              shopifyApiSecretKey: res?.data?.response?.shopifyApiSecretKey||"",
              shopifyHostName: res?.data?.response?.shopifyHostName ? res?.data?.response?.shopifyHostName.replace(".myshopify.com", ""):""
            });
            setIsKeyValid(false);
          }
        })
        .catch((err) => {
          console.error("add card error: ", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log("add card error: ", error);
      setLoading(false);
    }
  };

  const validateShopifyKeys = async () => {
    if (step === 1) {
      onClose();
      return;
    }
    try {
      setIsValidLoading(true);
      const payload = {
        shopifyApiKey: shopifyForm?.shopifyApiKey,
        shopifyApiSecretKey: shopifyForm?.shopifyApiSecretKey,
        shopifyHostName:
          shopifyForm?.shopifyHostName?.replace(".myshopify.com", "") +
          ".myshopify.com",
      };

      await axios
        .post(`${config.apiUrl}/shopify/check-key`, payload)
        .then((res) => {
          if (res?.data?.success) {
            setIsKeyValid(true);
            setIsValid(true);
            toast.success(res?.data?.message);
          } else {
            toast.error("Key, Secret Key or Host Name is not valid.");
            setIsKeyValid(false);
          }
        })
        .catch((err) => {
          console.error("check-key error: ", err);
          toast.error(" Key or Secret Key or Host Name is not valid.");
          setIsKeyValid(false);
        })
        .finally(() => {
          setIsValidLoading(false);
        });
    } catch (error) {
      console.log("check-key error: ", error);
      setIsValidLoading(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      let user = JSON.parse(localStorage.getItem("client"));
      const payload = {
        userId: user?._id,
        shopifyApiKey: shopifyForm?.shopifyApiKey,
        shopifyApiSecretKey: shopifyForm?.shopifyApiSecretKey,
        shopifyHostName:
          shopifyForm?.shopifyHostName?.replace(".myshopify.com", "") +
          ".myshopify.com",
      };

      await axios
        .post(`${config.apiUrl}/shopify/add-key`, payload)
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            setIsKeyValid(false);
            setIsValid(false);
            onClose();
            getShopifyKeys();
          }
        })
        .catch((err) => {
          console.error("add card error: ", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log("add card error: ", error);
      setLoading(false);
    }
  };

  const onClose = () => {
    const client = JSON.parse(localStorage.getItem("client"));
    client.isFirstLogin = false;
    localStorage.setItem("client", JSON.stringify(client));

    setShowShopifyModal(false);
  };

  return (
    <Modal centered show={showShopifyModal} onHide={onClose} size="md">
      <Form validated={isValid} onSubmit={handleSave}>
        <Modal.Header closeButton variant="danger">
          {step === 1 ? (
            <Modal.Title>Do you want to link your Shopify?</Modal.Title>
          ) : (
            <Modal.Title>LINK SHOPIFY</Modal.Title>
          )}
        </Modal.Header>
        {step === 2 && (
          <Modal.Body>
            <div>
              <div className="row">
                <div className="col-12">
                  <Form.Group>
                    <Form.Label>Shopify Api Key</Form.Label>
                    <Form.Control
                      style={{ height: "48px" }}
                      placeholder="Shopify Api Key"
                      type="text"
                      name="shopifyApiKey"
                      value={shopifyForm?.shopifyApiKey || ""}
                      onChange={handleFieldChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the Shopify Api Key!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group>
                    <Form.Label>Shopify Api Secret Key</Form.Label>
                    <Form.Control
                      style={{ height: "48px" }}
                      placeholder="Shopify Api Secret Key"
                      type="text"
                      name="shopifyApiSecretKey"
                      value={shopifyForm?.shopifyApiSecretKey || ""}
                      onChange={handleFieldChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the Shopify Api Secret Key!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group>
                    <Form.Label>Shopify Host Name</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        aria-describedby="basic-addon2"
                        style={{ height: "48px" }}
                        placeholder="Shopify Host Name"
                        type="text"
                        name="shopifyHostName"
                        value={
                          shopifyForm?.shopifyHostName ? 
                          shopifyForm?.shopifyHostName?.replace(
                            ".myshopify.com",
                            ""
                          ) : ""
                        }
                        onChange={handleFieldChange}
                        required
                      />
                      <InputGroup.Text id="basic-addon2">
                        .myshopify.com
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      Please enter the Shopify Host Name!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <div className="col-12 justify-content-between d-flex ">
            <Button
              variant="contained"
              color={step === 1 ? "secondary" : "primary"}
              className={classes.button}
              type="button"
              onClick={validateShopifyKeys}
              disabled={step === 1 ? false : (shopifyForm?.shopifyApiKey.trim()==="" || shopifyForm?.shopifyApiSecretKey.trim()==="" || shopifyForm?.shopifyHostName.trim()==="")}
            >
              {isValidLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              {step === 1 ? "Skip" : "Validate"}
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={step === 2 && <AddCircleIcon />}
              type={step === 1 ? "button" : "submit"}
              disabled={step === 1 ? false : !isKeyValid}
              onClick={() => {
                if (step === 1) {
                  setStep(2);
                }
              }}
            >
              {loading && isKeyValid && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              {step === 1 ? "Proceed" : "Save"}
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ShopifyModal;
