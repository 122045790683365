import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ChartSkeleton from "../ChartSkeleton";
import config from "../../config";
import { getFileDimensions } from "../../helper/getFileDimensions";
import { uploadFileOnS3 } from "../../helper/s3Uploader";
import services from "../../services";
import ProductTable from "./ProductTable";
import ProductAddUpdateModal from "./ProductAddUpdateModal";
import axios from "axios";
import { addonCategory } from "../../utils/utils";
import ShopifyProductTable from "./ShopifyProductTable";

toast.configure();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #f2f2f2",
  },
}));

const Campaigns = (props) => {
  const classes = useStyles();
  const { setPage } = props;
  const theme = useTheme();
  const [value, setValue] = React.useState(1);
  const { client, category } = useSelector((state) => state.client);
  const [campaignForm, setCampaignForm] = useState({
    purchase_options: ["xclusit_store"],
    color_options: [],
    // isXclusitStore: true,
  });
  const [past, setPast] = useState([]);
  const [present, setPresent] = useState([]);
  const [selectMonthFilter, setSelectMonthFilter] = useState("");
  const [output1, setOutput1] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [isMode, setIsMode] = useState("add");
  const [variantsList, setVariantsList] = useState([]);
  const [allVariantsList, setAllVariantsList] = useState([]);
  const [allNewMainCategoriesList, setAllNewMainCategoriesList] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [taxCategories, setTaxCategories] = useState([]);
  const [addonCatErrors, setAddCatErrors] = useState({});
  const [shopifyKeys, setShopifyKeys] = useState(null);

  useEffect(() => {
    if (fileList?.length > 5) {
      setErrorMessage("You can only upload up to 5 files.");
    } else {
      setErrorMessage("");
    }
  }, [fileList]);

   const getShopifyKeys = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("client"));
        const payload = {
          userId: user?._id,
        };
        await axios
          .get(`${config.apiUrl}/shopify/get-key`, { params: payload })
          .then((res) => {
            if (res?.data?.success) {
              setShopifyKeys({
                shopifyApiKey: res?.data?.response?.shopifyApiKey,
                shopifyApiSecretKey: res?.data?.response?.shopifyApiSecretKey,
                shopifyHostName: res?.data?.response?.shopifyHostName,
              });
            }
          })
          .catch((err) => {
            console.error("add card error: ", err);
            setShopifyKeys(null);
          })
          .finally(() => {
            // setLoading(false);
          });
      } catch (error) {
        console.log("add card error: ", error);
        setShopifyKeys(null);
        // setLoading(false);
      }
    };

    useLayoutEffect(() => {
      getShopifyKeys();
    }, []);


  // const handlePreview = (file) => {
  //   const isImage = file.type.startsWith("image/");
  //   const isVideo = file.type.startsWith("video/");
  //   if (isImage) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const imageUrl = reader.result;
  //     };
  //     reader.readAsDataURL(file);
  //   } else if (isVideo) {
  //   }
  // };

  // const handleRemoveFile = (file) => {
  //   setFileList((prevFileList) => prevFileList.filter((item) => item !== file));
  //   const filteredImages = campaignForm.images.filter((item) => item !== file);
  //   setCampaignForm({
  //     ...campaignForm,
  //     images: filteredImages,
  //   });
  //   setErrorMessage("");
  // };

  let tempFile = [];
  const propsUploadFile = {
    onRemove: (file) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item !== file)
      );

      const filteredImages = campaignForm.images.filter(
        (item) => item !== file
      );
      setCampaignForm({
        ...campaignForm,
        images: filteredImages,
      });
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the maximum allowed size (50MB).");
        return false;
      } else {
        setErrorMessage("");
      }

      // If it's an image or video, add it to the fileList
      if (isImage || isVideo) {
        setFileList((prevFileList) => [...prevFileList, file]);
        tempFile.push(file);
        setCampaignForm({
          ...campaignForm,
          images: campaignForm?.images
            ? [...campaignForm.images, ...tempFile]
            : [...tempFile],
        });
      } else {
        setErrorMessage(
          "Invalid file type. Only images and videos are allowed."
        );
        return false;
      }
      return false;
    },
    fileList,
    multiple: true,
    showUploadList: false,
  };

  // const notify = (type, text) =>
  //   toast[type](text, {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });

  // const convertArrayStructure = () => {
  //   return allNewMainCategoriesList.flatMap((item) => {
  //     const newItems = [];
  //     let currentItem = {};

  //     const initializeNewItem = () => {
  //       currentItem = {};
  //       // Map addon_category fields to the newItem object
  //       item?.addon_category.forEach((category) => {
  //         currentItem[category.name] = category.value;
  //       });
  //       currentItem.variant = [];
  //     };

  //     initializeNewItem();

  //     item?.addon_options.forEach((option) => {
  //       let nameObj = null;
  //       let priceObj = null;

  //       option.variant.forEach(({ property, value }) => {
  //         if (property === "Name") {
  //           nameObj = { property, value };
  //         } else if (property === "Price") {
  //           priceObj = { property, value: parseFloat(value) || "" };
  //         }

  //         // If both Name and Price are present in current object, push the current item and start a new one
  //         if (nameObj && priceObj) {
  //           currentItem.variant.push(nameObj, priceObj);
  //           newItems.push(currentItem);
  //           initializeNewItem();
  //           nameObj = null;
  //           priceObj = null;
  //         }
  //       });
  //     });

  //     // Push the last constructed currentItem if it has variants
  //     if (currentItem.variant.length > 0) {
  //       newItems.push(currentItem);
  //     }

  //     return newItems;
  //   });
  // };

  // const handleCreate = async (e) => {
  //   e.preventDefault();
  //   const form = e.currentTarget;
  //   if (form.checkValidity() === false) {
  //     setValidated(true);
  //     e.stopPropagation();
  //     return;
  //   }

  //   if (Object.keys(addonCatErrors).length) {
  //     return;
  //   }

  //   let dimensions = null;
  //   if (fileList.length) {
  //     dimensions = await getFileDimensions(fileList[0]);
  //   }

  //   campaignForm.client_id = client._id;
  //   campaignForm.url = campaignForm?.url?.toLowerCase();
  //   if (campaignForm?.category === "Food and Drink") {
  //     campaignForm.variants = convertArrayStructure() || [];
  //   } else {
  //     campaignForm.variants = allVariantsList || [];
  //   }

  //   await uploadFileOnS3(fileList, dimensions, "product")
  //     .then((res) => {
  //       const files = res.map((item) => {
  //         return { images_url: item.Location };
  //       });
  //       campaignForm.images = files;

  //       services.CampaignServices.addProduct(campaignForm)
  //         .then((res) => {
  //           getProduct();
  //           if (res?.data?.err) {
  //             setLoading(false);
  //             notify("error", res?.data?.message);
  //             setErrorMessage("");
  //             return;
  //           }
  //           if (res?.data?.message === "Product created successfully !!") {
  //             notify("success", "Product created successfully.");
  //             setShowCreate(false);
  //             setCampaignForm({
  //               purchase_options: ["xclusit_store"],
  //               color_options: [],
  //             });
  //             setFileList([]);
  //             getProduct();
  //             getProductCoupon();
  //             setLoading(false);
  //             setErrorMessage("");
  //           }
  //         })
  //         .catch((err) => {
  //           notify("error", "Something went to wrong.");
  //           console.log("add product error", err);
  //           setLoading(false);
  //           setErrorMessage("");
  //         });
  //     })
  //     .catch((error) => {
  //       notify("error", "Something went to wrong.");
  //       console.log("image upload error", error);
  //       setLoading(false);
  //       setErrorMessage("");
  //     });
  // };

  // const getProductCoupon = () => {
  //   const current = new Date().toUTCString();
  //   const apast = [],
  //     apresent = [],
  //     afuture = [];

  //   services.CampaignServices.getProduct()
  //     .then((res) => {
  //       res?.data?.data?.campaigns.map((campaign) => {
  //         const campaign_start_date = new Date(
  //           campaign.campaign_start_date
  //         ).toUTCString();
  //         const campaign_expire_date = new Date(
  //           campaign.campaign_expiration_date
  //         ).toUTCString();

  //         if (moment(campaign_expire_date).isBefore(current))
  //           apast.push(campaign); //Populate past campaigns
  //         if (
  //           moment(campaign_start_date).isSameOrBefore(current) &&
  //           moment(campaign_expire_date).isSameOrAfter(current)
  //         )
  //           apresent.push(campaign); // Populate present campaigns
  //         if (moment(campaign_start_date).isAfter(current))
  //           afuture.push(campaign); // Populate future campaigns

  //         return true;
  //       });
  //       setPast(apast);
  //       setPresent(apresent);
  //     })
  //     .catch((err) => console.log("getCampaigns error", err));
  // };

  const getProduct = () => {
    const current = new Date().toUTCString();
    const apast = [],
      apresent = [],
      afuture = [],
      arr = [];

    services.CampaignServices.getShopifyProductsall()
      .then((res) => {
        res?.data?.products.map((campaign) => {
          const array = res?.data?.data?.products;

          const campaign_start_date = new Date(
            campaign.campaign_start_date
          ).toUTCString();
          const campaign_expire_date = new Date(
            campaign.campaign_expiration_date
          ).toUTCString();

          if (moment(campaign_expire_date).isBefore(current))
            apast.push(campaign); //Populate past campaigns
          if (
            moment(campaign_start_date).isSameOrBefore(current) &&
            moment(campaign_expire_date).isSameOrAfter(current)
          )
            arr.push(campaign); // Populate present campaigns
          if (moment(campaign_start_date).isAfter(current))
            afuture.push(campaign); // Populate future campaigns
          setPresent(array);

          return true;
        });
        setPast(apast);
      })
      .catch((err) => console.log("getCampaigns error", err));
  };

  useEffect(() => {
    getProduct();
  }, []);

  // const resetForm = () => {
  //   setShowCreate(false);
  //   setCampaignForm({
  //     purchase_options: ["xclusit_store"],
  //     color_options: [],
  //   });
  //   setFileList([]);
  //   setErrorMessage("");
  // };

  // const onCloseModal = () => {
  //   setValidated(false);
  //   setCampaignForm({
  //     purchase_options: ["xclusit_store"],
  //     color_options: [],
  //   });
  //   setFileList([]);
  //   setShowCreate(false);
  //   setLoading(false);
  //   setErrorMessage("");
  //   setAllVariantsList([]);
  //   setAddCatErrors({})
  // };

  useEffect(() => {
    setOutput1([]);
    present.filter((val) => {
      if (
        val.category &&
        val.category.toLowerCase().includes(selectMonthFilter.toLowerCase())
      ) {
        setOutput1((output) => [...output, val]);
      }
    });
  }, [selectMonthFilter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const handlePurchaseOptionsChange = (e) => {
  //   const { name, value } = e.target;
  //   setCampaignForm((prev) => ({
  //     ...prev,
  //     [name]: [value],
  //   }));
  // };

  // const handleXclusitStoreChange = (e) => {
  //   const { name, checked, value } = e.target;
  //   setCampaignForm({
  //     ...campaignForm,
  //     // isXclusitStore: checked,
  //     isReturnable: null,
  //     returnDays: "",
  //     purchase_options: [value],
  //     color_options: [],
  //     category: "",
  //     subcategory: "",
  //     url: "",
  //     delivery_fees: "",
  //     shipping_days: "",
  //     taxCode: "",
  //   });
  //   setAllVariantsList([]);
  // };

  // const handleProductFieldChange = (e) => {
  //   const { name, value, required } = e.target;

  //   // Check if the input is only white spaces
  //   const storedValue = value.trim() === "" ? "" : value;

  //   if (required && storedValue === "") {
  //     e.target.setCustomValidity(
  //       "This field cannot be blank or contain only spaces."
  //     );
  //   } else {
  //     e.target.setCustomValidity("");
  //   }

  //   // Set the value to the original or an empty string if it's just white space
  //   setCampaignForm((prev) => ({
  //     ...prev,
  //     [name]: storedValue,
  //   }));
  // };

  // const handleVariantChange = (e, index, innerIndex) => {
  //   const { name, value, required, type, checked } = e.target;

  //   // Handle checkbox value as true/false
  //   const fieldValue = type === "checkbox" ? checked : value;

  //   if (required && !fieldValue.toString().trim()) {
  //     e.target.setCustomValidity(
  //       "This field cannot be blank or contain only spaces."
  //     );
  //   } else {
  //     e.target.setCustomValidity("");
  //   }

  //   setAllVariantsList((prevState) => {
  //     const updatedVariants = [...prevState];
  //     updatedVariants[index].variant[innerIndex].value = fieldValue;
  //     return updatedVariants;
  //   });
  // };

  console.log("variantsList===>", variantsList);

  // const handleAddVariant = () => {
  //   const newVariant = variantsList.map((variant) => ({
  //     ...variant,
  //     value: "",
  //   }));

  //   setAllVariantsList((prevState) => [
  //     ...prevState,
  //     { variant: [...newVariant, { property: "quantity", value: "" }] },
  //   ]);
  // };

  // const handleRemoveVariant = (index) => {
  //   setAllVariantsList((prevState) =>
  //     prevState.filter((_, idx) => idx !== index)
  //   );
  // };

  // const handleAddNewMainFoodCategory = () => {
  //   // Create a new variant array, initializing fields
  //   const newVariant = variantsList.map((variant) => ({
  //     ...variant,
  //     // value: variant.property === "Price" ? 0 : "", // Set Price to 0 and other values to an empty string
  //     value: "",
  //   }));

  //   // Sort the variants to ensure Name comes first, followed by Price
  //   const sortedNewVariant = newVariant.sort((a, b) => {
  //     if (a.property === "Name") return -1;
  //     if (b.property === "Name") return 1;
  //     if (a.property === "Price") return -1;
  //     if (b.property === "Price") return 1;
  //     return "";
  //   });

  //   // Create the new main category with the sorted variants
  //   const newMainCategory = {
  //     addon_category: addonCategory(),
  //     addon_options: [
  //       {
  //         variant: sortedNewVariant,
  //       },
  //     ],
  //   };

  //   // Update the state with the new main category
  //   setAllNewMainCategoriesList((prev) => [...prev, newMainCategory]);
  // };

  // const handleAddNewAddonOptions = (mainIndex) => {
  //   // Create a new variant array, ensuring initial fields are empty
  //   const newVariant = variantsList.map((variant) => ({
  //     ...variant,
  //     value: "",
  //   }));

  //   // Sort the variants to ensure Name comes first, followed by Price
  //   const sortedNewVariant = newVariant.sort((a, b) => {
  //     if (a.property === "Name") return -1;
  //     if (b.property === "Name") return 1;
  //     if (a.property === "Price") return -1;
  //     if (b.property === "Price") return 1;
  //     return 0;
  //   });

  //   // Create the new addon option with the sorted variants
  //   const newAddonOption = {
  //     variant: sortedNewVariant,
  //   };

  //   setAllNewMainCategoriesList((prev) => {
  //     const updatedCategories = [...prev];

  //     if (updatedCategories[mainIndex]) {
  //       updatedCategories[mainIndex].addon_options.push(newAddonOption);

  //       // Validate maxselection after adding a new option
  //       const maxSelection = updatedCategories[mainIndex].addon_category.find(
  //         (cat) => cat.name === "maxselection"
  //       )?.value;

  //       if (maxSelection) {
  //         const addonOptionsLength =
  //           updatedCategories[mainIndex].addon_options.length;

  //         if (addonOptionsLength < maxSelection) {
  //           setAddCatErrors((prevErrors) => ({
  //             ...prevErrors,
  //             [`maxselection-${mainIndex}`]: `At least ${maxSelection} addon options are required.`,
  //           }));
  //         } else {
  //           setAddCatErrors((prevErrors) => {
  //             const newErrors = { ...prevErrors };
  //             delete newErrors[`maxselection-${mainIndex}`];
  //             return newErrors;
  //           });
  //         }
  //       }
  //     }

  //     return updatedCategories;
  //   });
  // };

  // const handleRemoveMainCategory = (index) => {
  //   setAllNewMainCategoriesList((prev) =>
  //     prev.filter((_, idx) => idx !== index)
  //   );
  // };

  // const handleRemoveAddonOption = (categoryIndex, optionIndex) => {
  //   setAllNewMainCategoriesList((prev) => {
  //     const updatedCategories = [...prev];

  //     if (
  //       updatedCategories[categoryIndex] &&
  //       updatedCategories[categoryIndex].addon_options
  //     ) {
  //       updatedCategories[categoryIndex].addon_options = updatedCategories[
  //         categoryIndex
  //       ].addon_options.filter((_, idx) => idx !== optionIndex);

  //       // Validate maxselection after removing an option
  //       const maxSelection = updatedCategories[
  //         categoryIndex
  //       ].addon_category.find((cat) => cat.name === "maxselection")?.value;

  //       if (maxSelection) {
  //         const addonOptionsLength =
  //           updatedCategories[categoryIndex].addon_options.length;

  //         if (addonOptionsLength < maxSelection) {
  //           setAddCatErrors((prevErrors) => ({
  //             ...prevErrors,
  //             [`maxselection-${categoryIndex}`]: `At least ${maxSelection} addon options are required.`,
  //           }));
  //         } else {
  //           setAddCatErrors((prevErrors) => {
  //             const newErrors = { ...prevErrors };
  //             delete newErrors[`maxselection-${categoryIndex}`];
  //             return newErrors;
  //           });
  //         }
  //       }
  //     }

  //     return updatedCategories;
  //   });
  // };

  // const handleAddonCategoryChange = (
  //   mainIndex,
  //   mainCatItemIndex,
  //   key,
  //   value
  // ) => {
  //   const updatedCategories = [...allNewMainCategoriesList];
  //   const selectedCategory =
  //     updatedCategories[mainIndex].addon_category[mainCatItemIndex];

  //   selectedCategory[key] = value; // Update the value (e.g., for isPaid, maxselection, etc.)

  //   // Check if the key is "maxselection"
  //   if (selectedCategory.name === "maxselection") {
  //     const maxSelection = Number(value); // Convert to number if it's not already
  //     const addonOptionsLength =
  //       updatedCategories[mainIndex].addon_options.length;
  //     if (addonOptionsLength < maxSelection) {
  //       // Set error message for the maxselection field
  //       setAddCatErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [`maxselection-${mainIndex}`]: `At least ${maxSelection} addon options are required.`,
  //       }));
  //     } else {
  //       // Remove the error if validation passes
  //       setAddCatErrors((prevErrors) => {
  //         const newErrors = { ...prevErrors };
  //         delete newErrors[`maxselection-${mainIndex}`];
  //         return newErrors;
  //       });
  //     }
  //   }

  //   // Handle the logic for isPaid field toggle (optional based on your logic)
  //   if (selectedCategory.name === "ispaid" && value === false) {
  //     updatedCategories[mainIndex].addon_options = updatedCategories[
  //       mainIndex
  //     ].addon_options.map((option) => ({
  //       ...option,
  //       variant: option.variant.map((variantItem) => ({
  //         ...variantItem,
  //         value: variantItem.property === "Price" ? "" : variantItem.value,
  //       })),
  //     }));
  //   }

  //   setAllNewMainCategoriesList(updatedCategories); // Update the state with the modified categories
  // };

  // const handleAddonOptionChange = (
  //   mainIndex,
  //   optionIndex,
  //   variantIndex,
  //   field,
  //   value
  // ) => {
  //   setAllNewMainCategoriesList((prev) => {
  //     const updatedCategories = [...prev];
  //     const option = updatedCategories[mainIndex]?.addon_options[optionIndex];
  //     const variant = option?.variant[variantIndex];

  //     if (variant) {
  //       // Determine the appropriate value based on the property type
  //       let newValue = value;

  //       if (variant.propertyType === "number") {
  //         newValue = value === "" ? "" : Number(value);
  //       } else if (value === "") {
  //         newValue = "";
  //       }

  //       // Directly update the field of the variant object
  //       updatedCategories[mainIndex].addon_options[optionIndex].variant[
  //         variantIndex
  //       ] = { ...variant, [field]: newValue };
  //     }

  //     return updatedCategories;
  //   });
  // };

  console.log("allNewMainCategoriesList===>", allNewMainCategoriesList);

  // category new api
  const fetchCategories = async () => {
    await axios
      .get(`${config.apiUrl}/category/get-category`)
      .then((res) => {
        setAllCategories(res?.data?.data?.categoryList);
      })
      .catch((err) => {
        console.error("fetch categories error: ", err);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // const handleCategoryChange = (e) => {
  //   const selectedCategory = e.target.value;

  //   setCampaignForm({
  //     ...campaignForm,
  //     category: selectedCategory,
  //   });

  //   const filteredSubCategories = allCategories.filter(
  //     (sub) => sub.category === selectedCategory
  //   );
  //   setSubCategories(filteredSubCategories);

  //   const variantsArr =
  //     allCategories?.find((i) => i.category === selectedCategory)
  //       ?.variantProperties || [];

  //   setVariantsList([...variantsArr]);

  //   // Filter out any extra occurrences of 'Name' and 'Price'
  //   const filteredVariantsArr = variantsArr.reduce((acc, item) => {
  //     // if (item.property === "Name" || item.property === "Price") {
  //     //   const exists = acc.find((v) => v.property === item.property);
  //     //   if (!exists) {
  //     //     // acc.push({ ...item, value: item.property === "Price" ? 0 : "" });
  //     //     acc.push({ ...item, value: "" });
  //     //   }
  //     // } else {
  //     acc.push({ ...item, value: "" });
  //     // }
  //     return acc;
  //   }, []);

  //   const sortedVariantsArr = filteredVariantsArr.sort((a, b) => {
  //     // Sort to ensure 'Name' comes first, followed by 'Price'
  //     if (a.property === "Name") return -1;
  //     if (b.property === "Name") return 1;
  //     if (a.property === "Price") return -1;
  //     if (b.property === "Price") return 1;
  //     return 0;
  //   });

  //   const newMainCategory = {
  //     addon_category: addonCategory(),
  //     addon_options: [{ variant: sortedVariantsArr }],
  //   };

  //   if (e.target.value !== "") {
  //     if (e.target.value === "Food and Drink") {
  //       setAllVariantsList([{ variant: [...sortedVariantsArr] }]);
  //       setAllNewMainCategoriesList([newMainCategory]);
  //       return;
  //     } else {
  //       setAllVariantsList([
  //         {
  //           variant: [
  //             ...sortedVariantsArr,
  //             { property: "quantity", value: "" },
  //           ],
  //         },
  //       ]);
  //       // setAllNewMainCategoriesList([newMainCategory]);
  //       return;
  //     }
  //   }
  //   setAllVariantsList([]);
  //   setAllNewMainCategoriesList([]);
  // };

  const fetchTaxCategories = () => {
    axios
      .get(`${config.apiUrl}/category/get-tax-category`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TAX_CATEGORY_TOKEN}`,
        },
      })
      .then((res) => {
        const options = res?.data?.data?.taxCategory?.map((i) => ({
          label: i.name,
          value: i.product_tax_code,
        }));
        setTaxCategories(options);
      })
      .catch((err) => {
        console.error("tax category res error: ", err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchTaxCategories();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Products</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div
        className="row my-3 align-items-center"
        style={{ marginLeft: "0", marginRight: "0" }}
      >
        <div className="col-md-7 col-12 pl-0 pr-0">
          <h4>Products</h4>
        </div>
        <div className="col-md-5 col-12 text-left text-md-right pl-0 pr-0">
          {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AddCircleIcon />}
            onClick={(_) => {
              setShowCreate(true);
              setIsMode("add");
              setCampaignForm({
                purchase_options: ["xclusit_store"],
                color_options: [],
                // isXclusitStore: true,
              });
            }}
          >
            PRODUCT
          </Button> */}
        </div>
      </div>
      <div className="row" style={{ marginLeft: "0", marginRight: "0" }}>
        <div className={"col pl-0 pr-0 " + classes.root}>
          {past ? (
            <>
              <TabPanel value={value} index={1} dir={theme.direction}>
               {(
                <ShopifyProductTable
                headings={[
                  "NO",
                  "PRODUCT",
                ]}
                setSelectMonthFilter={setSelectMonthFilter}
                data={selectMonthFilter ? output1 : present}
                period="present"
                shopifyKeys={shopifyKeys}
                {...props}
                />
               )}
              </TabPanel>
            </>
          ) : (
            <ChartSkeleton />
          )}
        </div>
      </div>
    </>
  );
};

export default Campaigns;
