import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import { AccountBalanceWallet as AccountBalanceWalletIcon } from "@mui/icons-material";
import CampaignIcon from "@mui/icons-material/Campaign";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LogoutIcon from "@mui/icons-material/Logout";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PaymentsIcon from "@mui/icons-material/Payments";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MenuList = ({
  history,
  page,
  setPage,
  paymentStatus,
  isLegacyUser,
  noPlan,
  logout,
}) => {
  const classes = useStyles();

  if (paymentStatus?.isActivePlan || isLegacyUser || noPlan) { // TODO: remove noPlan if not needed in future
    return (
      <List component="nav" className={classes.root}>
        <ListItem 
          button
          selected={page === "dashboard"}
          onClick={(_) => setPage("dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          button
          selected={page === "campaigns"}
          onClick={(_) => setPage("campaigns")}
        >
          <ListItemIcon>
            <RecordVoiceOverIcon />
          </ListItemIcon>
          <ListItemText primary="Campaigns" />
        </ListItem>
        <ListItem
          button
          selected={page === "promotions"}
          onClick={(_) => setPage("promotions")}
        >
          <ListItemIcon>
            <CampaignIcon />
          </ListItemIcon>
          <ListItemText primary="Promotions" />
        </ListItem>
        <ListItem
          button
          selected={page === "ProductCampaign"}
          onClick={(_) => setPage("ProductCampaign")}
        >
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>
        <ListItem
          button
          selected={page === "Orders"}
          onClick={(_) => setPage("Orders")}
        >
          <ListItemIcon>
            <LocalMallIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem>
        <ListItem
          button
          selected={page === "Requests"}
          onClick={(_) => setPage("Requests")}
        >
          <ListItemIcon>
            <ProductionQuantityLimitsIcon />
          </ListItemIcon>
          <ListItemText primary="Requests" />
        </ListItem>
        <ListItem
          button
          className={classes.nested}
          selected={page === "invoices"}
          onClick={(_) => setPage("invoices")}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Invoices" />
        </ListItem>
        {!isLegacyUser &&
          !noPlan && ( // TODO: remove noPlan if not needed
            <>
              <ListItem
                button
                className={classes.nested}
                selected={page === "plan"}
                onClick={(_) => setPage("plan")}
              >
                <ListItemIcon>
                  <SubscriptionsIcon />
                </ListItemIcon>
                <ListItemText primary="Plan" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                selected={page === "payment_method"}
                onClick={(_) => setPage("payment_method")}
              >
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary="Payment Method" />
              </ListItem>
            </>
          )}
        <ListItem
          button
          className={classes.nested}
          selected={page === "payout_history"}
          onClick={(_) => setPage("payout_history")}
        >
          <ListItemIcon>
            <PaymentsIcon />
          </ListItemIcon>
          <ListItemText primary="Payout History" />
        </ListItem>
        <ListItem
          button
          selected={page === "profile"}
          onClick={(_) => setPage("profile")}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>
        <ListItem
          button
          selected={page === "shopify"}
          onClick={(_) => setPage("shopify")}
        >
          <ListItemIcon>
            <WarehouseIcon />
          </ListItemIcon>
          <ListItemText primary="Shopify" />
        </ListItem>
        <ListItem button onClick={(_) => logout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    );
  } else {
    return (
      <List component="nav" className={classes.root}>
        <ListItem
          button
          className={classes.nested}
          selected={page === "payment_method"}
          onClick={(_) => setPage("payment_method")}
        >
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="Payment Method" />
        </ListItem>
        {/* <ListItem
          button
          className={classes.nested}
          selected={page === "payout_history"}
          onClick={(_) => setPage("payout_history")}
        >
          <ListItemIcon>
            <PaymentsIcon />
          </ListItemIcon>
          <ListItemText primary="Payout History" />
        </ListItem> */}
        {!isLegacyUser &&
          !noPlan && ( // TODO: remove noPlan if not needed
            <ListItem
              button
              className={classes.nested}
              selected={page === "plan"}
              onClick={(_) => setPage("plan")}
            >
              <ListItemIcon>
                <SubscriptionsIcon />
              </ListItemIcon>
              <ListItemText primary="Plan" />
            </ListItem>
          )}
        <ListItem button onClick={(_) => logout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    );
  }
};

export default MenuList;
