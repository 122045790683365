import config from "../config";
import { axiosConfig } from "../utils/utils";

const accessToken = localStorage.getItem("token");

export default {
  addCampaign: async (campaign) => {
    return await axiosConfig(
      "post",
      `${config.apiUrl}/campaigns`,
      campaign,
      accessToken
    );
  },
  addPromotion: async (campaign) => {
    return await axiosConfig(
      "post",
      `${config.apiUrl}/promotions`,
      campaign,
      accessToken
    );
  },
  addProduct: async (Productcampaign) => {
    return await axiosConfig(
      "post",
      `${config.apiUrl}/product`,
      Productcampaign,
      accessToken
    );
  },
  uploadImage: async (imageUpload) => {
    return await axiosConfig(
      "post",
      `${config.apiUrl}/upload/image`,
      imageUpload,
      accessToken
    );
  },
  getCampaign: async (campaignId) => {
    return await axiosConfig(
      "get",
      `${config.apiUrl}/campaigns/${campaignId}`,
      null,
      accessToken
    );
  },
  getPromotion: async (campaignId) => {
    return await axiosConfig(
      "get",
      `${config.apiUrl}/promotions/${campaignId}`,
      null,
      accessToken
    );
  },
  enable_disable_promotion: async (campaignId, value) => {
    return await axiosConfig(
      "post",
      `${config.apiUrl}/promotions/enableDisable/${campaignId}/${value}`,
      null,
      accessToken
    );
  },
  getProduct: async (id) => {
    return await axiosConfig(
      "get",
      `${config.apiUrl}/product?id=${id}`,
      null,
      accessToken
    );
  },
  updateCampaign: async (campaignId, campaign) => {
    return await axiosConfig(
      "patch",
      `${config.apiUrl}/campaigns/${campaignId}`,
      campaign,
      accessToken
    );
  },
  updatePromotion: async (campaignId, campaign) => {
    return await axiosConfig(
      "patch",
      `${config.apiUrl}/promotions/${campaignId}`,
      campaign,
      accessToken
    );
  },
  deleteCampaign: async (campaignId) => {
    return await axiosConfig(
      "delete",
      `${config.apiUrl}/campaigns/${campaignId}`,
      null,
      accessToken
    );
  },
  deletePromotion: async (campaignId) => {
    return await axiosConfig(
      "delete",
      `${config.apiUrl}/promotions/${campaignId}`,
      null,
      accessToken
    );
  },
  getCampaigns: async () => {
    const client = JSON.parse(localStorage.getItem("client"));
    return await axiosConfig(
      "get",
      `${config.apiUrl}/campaigns/${client._id}/campaigns`,
      null,
      accessToken
    );
  },
  getPromotions: async () => {
    const client = JSON.parse(localStorage.getItem("client"));
    return await axiosConfig(
      "get",
      `${config.apiUrl}/promotions/${client._id}/promotions`,
      null,
      accessToken
    );
  },
  getProductsall: async () => {
    const client = JSON.parse(localStorage.getItem("client"));
    return await axiosConfig(
      "get",
      `${config.apiUrl}/products?page=1&count=10&search&client_id=${client._id}`,
      null,
      accessToken
    );
  },
  getShopifyProductsall: async () => {
    const client = JSON.parse(localStorage.getItem("client"));
    return await axiosConfig(
      "get",
      `${config.apiUrl}/products?page=1&count=10&search&client_id=${client._id}`,
      null,
      accessToken
    );
  },
  getProducts: async () => {
    return await axiosConfig(
      "get",
      `${config.apiUrl}/products`,
      null,
      accessToken
    );
  },
};
